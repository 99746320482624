<ng-container *transloco="let t; scope: ''">
  <mat-toolbar *ngIf="data.mobileType === 'android'" color="primary" class="android-prompt">
    <button mat-button (click)="installPwa()">{{ t("pwa.android-text") }}</button>
    <button mat-icon-button (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>

  <div *ngIf="data.mobileType === 'ios'" class="ios-prompt">
    <button class="close-ios-inv" mat-button color="primary" (click)="close()"><mat-icon>close</mat-icon></button>
    <div>{{ t("pwa.ios-text") }}</div>
    <div>
      <img src="./assets/ios-menu-btn.png" alt="menu button" />
      <mat-icon>navigate_next</mat-icon>
      <img src="./assets/ios-add-btn.png" alt="add button" />
    </div>
  </div>
</ng-container>
