<!-- Button -->
<ng-container *ngIf="user$ | async as user">
  <button mat-icon-button [matMenuTriggerFor]="userActions">
    <span class="relative">
      <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
    </span>
  </button>

  <mat-menu [xPosition]="'before'" #userActions="matMenu">
    <ng-container *transloco="let t; scope: 'auth'">
      <div class="text-center">
        <span class="flex flex-col leading-none">
          <span class="text-md">{{ t("auth.auth-user-management.logged-in-as") }}</span>
          <span class="mt-1.5 text-md font-medium">{{ user.sUserName }}</span>
        </span>
      </div>
      <mat-divider class="my-2"></mat-divider>
      <!--      <button mat-menu-item>-->
      <!--        <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>-->
      <!--        <span>Profile</span>-->
      <!--      </button>-->
      <button mat-menu-item (click)="openSettingDrawer()">
        <mat-icon [svgIcon]="'heroicons_outline:cog'"></mat-icon>
        <span>{{ t("auth.auth-user-management.settings") }}</span>
      </button>
      <mat-divider class="my-2"></mat-divider>
      <button mat-menu-item (click)="signOut()">
        <mat-icon [svgIcon]="'heroicons_outline:logout'"></mat-icon>
        <span>{{ t("auth.auth-user-management.logout") }}</span>
      </button>
    </ng-container>
  </mat-menu>
</ng-container>
<app-settings></app-settings>
