import { CommonModule } from '@angular/common';
import { Injector, ModuleWithProviders, NgModule } from '@angular/core';
import { HookHandlerFactoryService, InjectorClass } from '@lobos/library';
import { ErrorLogToConsoleHandler } from './handler/error-log-to-console.handler';

const hookHandlers: any[] = [ErrorLogToConsoleHandler];

@NgModule({
  imports: [CommonModule],
  providers: [],
})
export class HookHandlerModule {
  constructor(injector: Injector, hookHandlerFactory: HookHandlerFactoryService) {
    InjectorClass.set(injector);

    for (const hookHandler of hookHandlers) {
      hookHandlerFactory.register(injector.get<any>(hookHandler));
    }
  }

  static forRoot(): ModuleWithProviders<HookHandlerModule> {
    return {
      ngModule: HookHandlerModule,
      providers: [],
    };
  }
}
