import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';
import { MarkdownModule } from 'ngx-markdown';
import { FuseModule } from '@fuse';
import { FuseConfigModule } from '@fuse/services/config';
import { FuseMockApiModule } from '@fuse/lib/mock-api';
import { CoreModule } from 'app/core/core.module';
import { appConfig } from 'app/core/config/app.config';
import { mockApiServices } from 'app/mock-api';
import { LayoutModule } from 'app/layout/layout.module';
import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';
import { CoreModule as LibCoreModule, ErrorModule, CrudModule } from '@lobos/library';
import { environment } from '../environments/environment';
import { NG_ENTITY_SERVICE_CONFIG } from '@datorama/akita-ng-entity-service';
import { HookHandlerModule } from './hooks/hook-handler/hook-handler.module';
import { HookTriggerModule } from './hooks/hook-trigger/hook-trigger.module';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { ServiceWorkerModule } from '@angular/service-worker';
import { PwaService } from './services/pwa.service';
import { LastFourYearsPipe } from './pipes/last-four-years/last-four-years.pipe';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { EhgBaseInterceptor } from './interceptors/ehg-base.interceptor';

const routerConfig: ExtraOptions = {
  preloadingStrategy: PreloadAllModules,
  scrollPositionRestoration: 'enabled',
};

const initializer = (pwaService: PwaService) => () => pwaService.initPwaPrompt();

@NgModule({
  declarations: [AppComponent],
  imports: [
    ErrorModule.forRoot({ showOutput: !environment.production || environment.debugging }),
    LibCoreModule.forRoot({
      baseUrl: environment.host,
      branchKey: environment.api.branchKey,
      webshopId: environment.api.webshopId,
      ignoreLanguageForUrls: true,
    }),
    HookHandlerModule.forRoot(),
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes, routerConfig),

    // Fuse, FuseConfig & FuseMockAPI
    FuseModule,
    FuseConfigModule.forRoot(appConfig),
    FuseMockApiModule.forRoot(mockApiServices),

    // Core module of your application
    CoreModule,
    // Layout module of your application
    LayoutModule,
    // 3rd party modules that require global configuration via forRoot
    MarkdownModule.forRoot({}),

    HookTriggerModule.forRoot(),

    environment.production ? [] : AkitaNgDevtools.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      registrationStrategy: 'registerImmediately',
    }),
    CrudModule.forRoot({
      apiUrl: environment.apiUrl,
    }),
  ],
  bootstrap: [AppComponent],
  providers: [
    LastFourYearsPipe,
    {
      provide: NG_ENTITY_SERVICE_CONFIG,
      useValue: { baseUrl: environment.host + environment.apiUrl },
    },
    { provide: APP_INITIALIZER, useFactory: initializer, deps: [PwaService], multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: EhgBaseInterceptor,
      multi: true,
    },
  ],
})
export class AppModule {}
