import { Pipe, PipeTransform } from '@angular/core';
import { CustomerPerformance } from '../../services/model/customer-performance.model';

@Pipe({
  name: 'lastFourYears',
})
export class LastFourYearsPipe implements PipeTransform {
  transform(customerPerformance: CustomerPerformance | undefined): number | string {
    if (customerPerformance) {
      return Math.ceil(
        customerPerformance.decQuantity12 +
          customerPerformance.decQuantity13 +
          customerPerformance.decQuantity14 +
          customerPerformance.decQuantity15,
      );
    }
    return '-';
  }
}
