import { Route } from '@angular/router';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';
import { AnonymousGuard, AuthorizedGuard } from '@lobos/library';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
  // Redirect empty path to '/example'
  { path: '', pathMatch: 'full', redirectTo: 'dashboards/customers' },

  // Redirect signed in user to the '/example'
  //
  // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
  // path. Below is another redirection for that path to redirect the user to the desired
  // location. This is a small convenience to keep all main routes together here on this file.
  { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'dashboards/customers' },

  // Auth routes for guests
  {
    path: '',
    canActivate: [AnonymousGuard],
    canActivateChild: [AnonymousGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: 'forgot-password',
        loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.module').then((m) => m.AuthForgotPasswordModule),
      },
      {
        path: 'reset-password',
        loadChildren: () => import('app/modules/auth/reset-password/reset-password.module').then((m) => m.AuthResetPasswordModule),
      },
      {
        path: 'sign-in',
        loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then((m) => m.AuthSignInModule),
      },
    ],
  },

  // Auth routes for authenticated users
  {
    path: '',
    canActivate: [AuthorizedGuard],
    canActivateChild: [AuthorizedGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: 'sign-out',
        loadChildren: () => import('app/modules/auth/sign-out/sign-out.module').then((m) => m.AuthSignOutModule),
      },
      {
        path: 'unlock-session',
        loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.module').then((m) => m.AuthUnlockSessionModule),
      },
    ],
  },

  // Admin routes
  {
    path: '',
    canActivate: [AuthorizedGuard],
    canActivateChild: [AuthorizedGuard],
    component: LayoutComponent,
    resolve: {
      initialData: InitialDataResolver,
    },
    children: [
      {
        path: 'dashboards',
        children: [
          {
            path: 'cockpit',
            loadChildren: () => import('app/modules/admin/dashboards/home/home.module').then((m) => m.HomeModule),
          },
          {
            path: 'customers',
            canActivateChild: [AuthorizedGuard],
            loadChildren: () => import('app/modules/admin/dashboards/customers/customer.module').then((m) => m.CustomerModule),
          },
          {
            path: 'suppliers',
            canActivateChild: [AuthorizedGuard],
            loadChildren: () => import('app/modules/admin/dashboards/suppliers/supplier.module').then((m) => m.SupplierModule),
          },
          {
            path: 'addresses',
            canActivateChild: [AuthorizedGuard],
            loadChildren: () => import('app/modules/admin/dashboards/address/address.module').then((m) => m.AddressModule),
          },
          {
            path: 'contacts',
            loadChildren: () => import('app/modules/admin/dashboards/contacts/contacts.module').then((m) => m.ContactsModule),
          },
          {
            path: 'statistic',
            loadChildren: () => import('app/modules/admin/dashboards/statistic/statistic.module').then((m) => m.StatisticModule),
          },
          {
            path: 'visit',
            loadChildren: () => import('app/modules/admin/dashboards/visit/visit.module').then((m) => m.VisitModule),
          },
          {
            path: 'visit-reports',
            loadChildren: () => import('app/modules/admin/dashboards/visit-reports/visit-reports.module').then((m) => m.VisitReportsModule),
          },
          {
            path: 'map',
            loadChildren: () => import('app/modules/admin/dashboards/map/map.module').then((m) => m.MapModule),
          },
        ],
      },
    ],
  },
];
