/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id: 'dashboards',
        title: 'CRM App',
        type: 'group',
        icon: 'heroicons_outline:chart-pie',
        children: [
          {
            id: 'dashboards.kunde',
            title: 'menu.customer',
            type: 'basic',
            icon: 'heroicons_outline:briefcase',
            link: '/dashboards/customers',
          },
          {
            id: 'dashboards.cockpit',
            title: 'menu.cockpit',
            type: 'basic',
            icon: 'heroicons_outline:chart-bar',
            link: '/dashboards/cockpit',
          },
          {
            id: 'dashboards.visit',
            title: 'menu.visit',
            type: 'basic',
            icon: 'heroicons_outline:calendar',
            link: '/dashboards/visit-reports',
          },
          {
            id: 'dashboards.map',
            title: 'menu.map',
            type: 'basic',
            icon: 'heroicons_outline:map',
            link: '/dashboards/map',
          },
        ],
    },
];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id: 'dashboards',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example',
    },
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id: 'dashboards',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example',
    },
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
      id: 'dashboards.kunde',
      title: 'menu.customer',
      type: 'basic',
      icon: 'heroicons_outline:briefcase',
      link: '/dashboards/customers',
    },
    {
        id: 'dashboards.cockpit',
        title: 'menu.cockpit',
        type: 'basic',
        icon: 'heroicons_outline:chart-bar',
        link: '/dashboards/cockpit',
    },
    {
        id: 'dashboards.visit',
        title: 'menu.visit',
        type: 'basic',
        icon: 'heroicons_outline:calendar',
        link: '/dashboards/visit-reports',
    },
    {
      id: 'dashboards.map',
      title: 'menu.map',
      type: 'basic',
      icon: 'heroicons_outline:map',
      link: '/dashboards/map',
    },
];
