// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
import { VERSION } from './version';

export const environment = {
  debugging: true,
  name: 'TESTING',
  host: 'https://adpwa-test.ehg-stahl.com',
  apiUrl: '/api',
  production: false,
  ssrPort: 4400,
  version: VERSION,
  api: {
    host: 'https://apitest.ehg-stahl.com',
    branchKey: 'E101',
    webshopId: 1,
  },
  auth: {
    routes: {
      home: '',
      login: '/sign-in',
    },
  },
  shopUrl: 'https://newtestshop.ehg-stahl.com',
};
