<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<div class="flex flex-auto justify-center w-full sm:p-4 md:p-8 bg-gray-200 dark:bg-card">
  <!-- Navigation -->
  <ng-container *ngIf="isScreenSmall">
    <fuse-vertical-navigation
      class="dark bg-gray-900 print:hidden"
      [mode]="'over'"
      [name]="'mainNavigation'"
      [navigation]="navigation.default"
      [opened]="false"
    >
      <!-- Navigation header hook -->
      <ng-container fuseVerticalNavigationContentHeader>
        <!-- Logo -->
        <div class="flex items-center h-20 pt-6 px-8">
          <img class="w-30" src="assets/images/logo/logo-text-on-dark.svg" alt="logo dark mode" />
        </div>
      </ng-container>
    </fuse-vertical-navigation>
  </ng-container>

  <!-- Wrapper -->
  <div class="flex flex-col items-center flex-auto min-w-0 max-w-360 sm:rounded-xl shadow-2xl dark:shadow-none overflow-hidden">
    <!-- Header -->
    <div class="relative flex flex-0 w-full h-16 sm:h-20 px-4 md:px-6 z-49 bg-card border-b dark:bg-default print:hidden">
      <ng-container *ngIf="!isScreenSmall">
        <!-- Logo -->
        <div class="flex items-center mx-2 max-w-20 lg:mr-8">
          <div class="hidden lg:flex">
            <!-- Light version -->
            <img class="dark:hidden" src="assets/images/logo/logo.png" alt="Logo image" />
            <!-- Dark version -->
            <img class="hidden dark:flex" src="assets/images/logo/logo-text-on-dark.svg" alt="Logo image" />
          </div>
          <!-- Small version -->
          <img class="flex lg:hidden w-24" src="../../../../../assets/images/logo/logo.png" alt="Logo image" />
        </div>
        <div class="flex items-center">
          <!-- Horizontal navigation -->
          <fuse-horizontal-navigation
            class="mr-2"
            [name]="'mainNavigation'"
            [navigation]="navigation.horizontal"
          ></fuse-horizontal-navigation>
        </div>
      </ng-container>
      <!-- Navigation toggle button -->
      <ng-container *ngIf="isScreenSmall">
        <div class="flex items-center">
          <button class="mr-2" mat-icon-button (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
          </button>
        </div>
      </ng-container>

      <!--      <ng-container *ngIf="!isScreenSmall">-->
      <!--        <div class="flex items-end justify-center w-full">-->
      <!--          <img class="dark:hidden w-80 flex" src="assets/images/ui/berge_normal.png" alt="Berge image" />-->
      <!--          <img class="hidden dark:flex w-80" src="assets/images/ui/berge_dark.png" alt="Berge image dark" />-->
      <!--        </div>-->
      <!--      </ng-container>-->
      <!-- Components -->
      <div class="flex items-center pl-2 ml-auto space-x-1 sm:space-x-2">
        <languages></languages>
        <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
        <!--        <search [appearance]="'bar'"></search>-->
        <!--        <shortcuts></shortcuts>-->
        <!--        <messages></messages>-->
        <!--        <notifications></notifications>-->
        <user></user>
      </div>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto w-full bg-default">
      <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
                 Otherwise, layout changes won't be registered and the view won't be updated! -->
      <router-outlet *ngIf="true"></router-outlet>
    </div>

    <!-- Footer -->
    <div
      class="relative flex flex-0 items-center justify-start w-full h-16 sm:h-20 px-6 sm:px-8 z-20 bg-card border-t dark:bg-default print:hidden"
    >
      <span class="font-medium text-secondary">EHG &copy; {{ currentYear }}</span>
    </div>
  </div>
</div>
